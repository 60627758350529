<template>
  <div class="app-container store-order">
    <div class="wrapper">
      <div v-scrollTop>
        <orderState
          ref="orderState"
          :query="query"
          :sup_this="sup_this"
          @updateCheck="updateCheck"
          @takeStatus="takeStatus"
        ></orderState>
        <formPart
          v-if="permission.search"
          ref="form"
          :query="query"
          :orderStatus="orderStatus"
          :sup_this="sup_this"
          :postData="postData"
          :inDispatch="false"
        ></formPart>
        <div class="flex-middle">
          <loadingBtn class="line" type="primary" size="small" @click="downloadPdf" v-if="permission.downloadPdf"
            >下载面单</loadingBtn
          >
          <template v-if="orderStatus === ''">
            <loadingBtn
              type="primary"
              size="small"
              @click="orderExport"
              v-p="['externaladmin:orderManage:orderList:all:export']"
              >全部订单导出</loadingBtn
            >
          </template>

          <BatchApplyWaybill
            :orderStatus="orderStatus"
            :data="factoryData"
            :sup_this="sup_this"
            v-p="['externaladmin:orderManage:orderList:all:batchApplyWaybill']"
          />
        </div>
        <tablePart
          ref="table"
          class="flex-one"
          :sup_this="sup_this"
          :tableLoading="tableLoading"
          :orderListData="data"
          :orderStatus="orderStatus"
          :permission="permission"
          :isWaybillCreate="true"
          @takeFactory="takeFactory"
        ></tablePart>
      </div>
      <div class="hidden_block"></div>
      <div class="pagination_wrapper">
        <PaginationBar :page="page" :size="size" :total="total" @refreshTableEventFun="refreshTableEventFun" />
      </div>
    </div>
  </div>
</template>

<script>
import orderState from '../../../order/module/orderListModule/orderState'
import formPart from '../../../order/module/orderListModule/formPart'
import tablePart from '../../../order/module/orderListModule/tablePart2'
import pagination from '../../../order/module/orderListModule/pagination'
import BatchApplyWaybill from '../../../order/module/orderListModule/batchApplyWaybill'

import saveAs from 'jszip/vendor/FileSaver.js'
import { initDataMixin } from '@/mixins'
import { downloadWaybillFile, exportAll } from '@/api/order/orderListData'
import { getValueFromObj } from '@/utils'
import handleSearchFormProps from '@/views/order/utils/handleSearchFormProps'

export default {
  name: 'orderOrderList',
  mixins: [initDataMixin],
  components: {
    orderState,
    formPart,
    tablePart,
    BatchApplyWaybill, //批量申请运单号

    pagination
  },
  data() {
    return {
      sup_this: this,
      url: '/externaladmin/orderService/externalOrder/orderList',
      method: 'post',
      initBool: false,
      mergeData: {
        orderItems: [
          {
            asc: false,
            column: 'order_time'
          },
          {
            asc: false,
            column: 'id'
          }
        ],
        isFormatChildName: 1,
        isReturnRelatedData: 1,
        isReturnWaybillChangeRecord: 1,
        isGetAllOrder: 1
      },
      orderStatus: '0',
      factoryData: [],
      permission: {},

      postData: {}
    }
  },
  computed: {
    handleSearchFormProps() {
      return handleSearchFormProps
    }
  },
  watch: {
    query: {
      handler(n, o) {
        let id = this.$route.query.id
        this.postData = Object.assign(this.params, { shopId: id })
      },
      immediate: true,
      deep: true
    }
  },
  mounted() {
    this.permission = this.$refs.orderState.childPermission || {}
    if (!this.permission) {
      this.$message.error('页面无权限！')
    }
  },
  methods: {
    beforeInitData() {
      let id = this.$route.query.id
      this.postData = Object.assign(this.params, { shopId: id })
    },
    initCallBack(res) {
      // console.log('ressssssss', res)
      let tempArr = []
      this.data.forEach((item) => {
        tempArr.push({
          isHeader: true,
          orderCode: item.orderCode,
          userName: item.userName,
          orderInfo: item
        })
        item.$sortTime = this.getSortTime(item)
        item.$lastTime = item.$sortTime[item.$sortTime.length - 1]
        tempArr.push(item)
      })
      this.data = tempArr
    },
    getSortTime(item) {
      let times = [
        {
          label: '导入时间',
          value: item.importTime,
          prop: 'importTime'
        },
        {
          label: '面单下载时间',
          value: item.expressWaybillDownloadTime,
          prop: 'expressWaybillDownloadTime'
        },
        {
          label: '取消时间',
          value: item.cancelTime,
          prop: 'cancelTime'
        },
        {
          label: '截单时间',
          value: item.cutoffTime,
          prop: 'cutoffTime'
        },
        {
          label: '排单时间',
          value: item.sendTime,
          prop: 'sendTime'
        },
        {
          label: '发货时间',
          value: item.deliveryTime,
          prop: 'deliveryTime'
        },
        {
          label: '退货申请时间',
          value: item.returnApplyTime,
          prop: 'returnApplyTime'
        },
        {
          label: '退货完成时间',
          value: item.returnCompleteTime,
          prop: 'returnCompleteTime'
        }
      ]
      if (item.exportStatus === 1 && item.exportTime) {
        times.push({
          label: '生产时间',
          value: item.exportTime,
          prop: 'exportTime'
        })
      }
      return times
        .filter((time) => time.value)
        .sort((a, b) => new Date(a.value).getTime() - new Date(b.value).getTime())
    },
    takeStatus(data) {
      this.orderStatus = data
    },
    takeFactory(data) {
      this.factoryData = data
      console.log(this.factoryData)
    },
    async downloadPdf() {
      /*if (!await this.getSelectionDataAllArr()) return
       if (this.selectionDataAllArr.length === 0) {
       this.$message.warning('请先选择订单')
       return
       }*/
      if (this.factoryData.length === 0) {
        return this.$message.warning('请先选择订单')
      }

      let res = await awaitFormResolve(
        downloadWaybillFile({
          idList: this.factoryData.map((item) => item.orderInfo.id)
        })
      )
      if (!res) return
      try {
        let text = JSON.parse(await res.text())
        if (/1001000/.test(text.code)) {
          await this.init()
          return this.$message.warning(text.message)
        }
      } catch (e) {}

      saveAs(res, '下载面单')
      this.$message.success('下载成功')
      await this.init()
    },
    async orderExport() {
      await this.beforeInit()
      let { params } = this
      params.shopId = this.$route.query.id
      let res = await awaitFormResolve(exportAll(params))
      if (res) {
        let date
        let time = getValueFromObj(this.$refs, 'form.time')
        if (Array.isArray(time) && time.length) {
          date = `${time[0]}--${time[1]}`
        } else {
          date = this.$moment().format('YYYY-MM-DD')
        }
        saveAs(res, `${date}-全部订单导出数据`)
        this.$message.success('导出成功')
      } else {
        this.$message.warning('无订单可导出，请输入正确数值后重试')
      }
    },
    updateCheck(newCheck) {
      this.permission = newCheck
    }
  }
}
</script>

<style lang="scss" scoped>
.app-container {
  overflow: hidden;
  padding: 20px 0 95px 15px;
  position: relative;
  .wrapper {
    height: 100%;
    overflow: auto;
    border-bottom: 1px solid #ebeef5;
    padding-right: 15px;
  }
  .pagination_wrapper {
    position: fixed;
    bottom: 32px;
    right: 45px;
    // background-color: rgb(25, 224, 158);
    z-index: 10;
  }
  .hidden_block {
    position: absolute;
    bottom: 95px;
    right: 0;
    width: 23px;
    height: 1px;
    background-color: #fff;
  }
  ::v-deep {
    .el-table {
      overflow: hidden;
    }
    .list-state-group .el-radio-button__inner {
      width: 135px;
    }
  }
}
</style>
